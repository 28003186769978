import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { hyphenate } from "../utils/urls"

const StyledPostFooter = styled.footer`
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  margin-top: ${props => props.theme.spacers.xl};
  padding-top: ${props => props.theme.spacers.sm};
`

const renderCategories = categories => {
  if (!categories.length) return null

  return <div>Filed Under: {renderTaxonomy("category", categories)}</div>
}

const renderTags = tags => {
  if (!tags.length) return null

  return <div>Tagged: {renderTaxonomy("tag", tags)}</div>
}

const renderTaxonomy = (taxonomyType, taxonomyList) =>
  taxonomyList.map((taxonomyItem, index) => (
    <span key={index}>
      {index ? ", " : ""}
      <a href={`/blog/${taxonomyType}/${hyphenate(taxonomyItem)}/`}>
        {taxonomyItem}
      </a>
    </span>
  ))

const PostFooter = ({ categories, tags, children }) => (
  <StyledPostFooter>
    {renderCategories(categories)}
    {renderTags(tags)}
    {children}
  </StyledPostFooter>
)

PostFooter.propTypes = {
  categories: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  children: PropTypes.node,
}

export default PostFooter
