import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { cx } from "emotion"
import { DiscussionEmbed } from "disqus-react"
import AnimatedHeading from "../components/animated-heading"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Time from "../components/time"
import Container from "../components/container"
import PostFooter from "../components/post-footer"

const StyledImg = styled(Img)`
  filter: brightness(50%);

  @media (min-width: 1000px) {
    height: 500px;
  }
`

const renderFeaturedImage = image => {
  if (image) {
    return <StyledImg fluid={image.childImageSharp.fluid} />
  }

  return null
}

const renderHeading = (image, title) => {
  if (image) {
    return <AnimatedHeading>{title}</AnimatedHeading>
  }

  return <h1>{title}</h1>
}

const StyledBlogPost = styled.div`
  max-width: 832px;
  margin: 0 auto ${props => props.theme.spacers.xl};

  .post-update {
    font-style: italic;
    background-color: ${props => props.theme.colors.lightGray};
    color: ${props => props.theme.colors.black};
    padding: ${props => props.theme.spacers.sm};
    margin: ${props =>
      `${props.theme.spacers.sm} -${props.theme.spacers.base}`};
  }
`

const StyledHeader = styled.header`
  margin-bottom: ${props => props.theme.spacers.sm};

  .has-featured-image & {
    @media (min-width: 1000px) {
      position: absolute;
      top: calc(500px / 2);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  h1 {
    margin-bottom: ${props => props.theme.spacers.base};

    @media (min-width: 1000px) {
      font-size: 3.1rem;
    }

    .has-featured-image & {
      @media (min-width: 1000px) {
        color: ${props => props.theme.colors.white};
        margin-top: 0;
      }
    }
  }

  time {
    .has-featured-image & {
      @media (min-width: 1000px) {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

const StyledBlogPostContent = styled.div`
  .has-featured-image & {
    @media (min-width: 1000px) {
      padding-top: ${props => props.theme.spacers.md};
    }
  }
`

const DisqusContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding-bottom: ${props => props.theme.spacers.lg};

  @media (min-width: 1000px) {
    padding-bottom: ${props => props.theme.spacers.xl};
  }
`

const BlogPost = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const { markdownRemark, site } = data // data.markdownRemark holds our post data
  const { frontmatter, html, excerpt, id } = markdownRemark
  const featuredImage = frontmatter.featured_image
    ? frontmatter.featured_image.publicURL
    : null

  const classNames = cx(
    { "has-featured-image": featuredImage != null },
    "blog-post-container"
  )

  // Disqus stuff
  const disqusShortname = "sheelahb"

  const disqusConfig = {
    identifier: id,
    title: frontmatter.title,
    url: `${site.siteMetadata.siteUrl}${frontmatter.url}`,
  }

  return (
    <Layout verticalPadding={false}>
      <SEO
        title={frontmatter.title}
        description={excerpt}
        isArticle
        publishDate={frontmatter.publishDate}
        path={frontmatter.url}
        image={featuredImage}
      />
      <div className={classNames}>
        {renderFeaturedImage(frontmatter.featured_image)}
        <Container>
          <StyledBlogPost>
            <StyledHeader>
              {renderHeading(frontmatter.featured_image, frontmatter.title)}
              <Time>{frontmatter.date}</Time>
            </StyledHeader>
            <StyledBlogPostContent dangerouslySetInnerHTML={{ __html: html }} />
            <PostFooter
              tags={frontmatter.tags || []}
              categories={frontmatter.categories || []}
            />
          </StyledBlogPost>
        </Container>
        <DisqusContainer id="disq">
          <Container>
            <DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />
          </Container>
        </DisqusContainer>
      </div>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query($url: String!) {
    markdownRemark(frontmatter: { url: { eq: $url } }) {
      html
      id
      excerpt(pruneLength: 150)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        publishDate: date
        url
        title
        tags
        categories
        featured_image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl: url
      }
    }
  }
`
